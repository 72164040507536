import React, { useState, useEffect } from "react";
import { Select, Input } from "antd";
import { Box, Grid, MenuItem } from "@mui/material";
import GeneralService from "../../../../services/general";
import MofService from "../../../../services/mof";
import { CODES } from "../../../../utils/responseCodes";
import { Toast } from "../../../../utils/alerts";
import AssignMofsBody from "./AssignMofsBody";
const { Option } = Select;

const AssignMofs = (props) => {
  const {companies,infoUsuarioLA,setOpenModalLoader,listaMof,selectedRows, setSelectedRows,mofxUsuario,getDatosMofArray} = props;

  const [clienteEmpresa, setClienteEmpresa] = useState(null);
  const [mofClientePuesto, setMofClientePuesto] = useState(null);
  const [puestoCliente, setPuestoCliente] = useState(null);
  const [infoEmpresa, setInfoEmpresa] = useState(null);
  const [infoCliente, setInfoCliente] = useState(null);
  const [infoPuestoCliente, setInfoPuestoCliente] = useState(null);


  useEffect(() => {
    if (infoEmpresa) 
    getClientesByEmpresa();
    setClienteEmpresa(null);
  }, [infoEmpresa]);

  useEffect(() => {
    if (infoCliente) 
    getPuestosxCliente();
    setPuestoCliente(null);
  }, [infoCliente]);

  useEffect(() => {
    if (infoCliente?.id_cliente && infoPuestoCliente?.id_puesto !== undefined) {
        getMofByClientePuesto();
        setMofClientePuesto(null);
    } else if (infoCliente?.id_cliente) {
        getMofByClientePuesto(infoCliente?.id_cliente, 0);
        setMofClientePuesto(null);
    }
}, [infoCliente?.id_cliente, infoPuestoCliente?.id_puesto]);

  const getClientesByEmpresa = async () => {
    await GeneralService.getClientesByEmpresa(infoEmpresa?.value)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.id_cliente;
            element.value = element.id_cliente;
            element.label = element.desCliente;
          });
          setClienteEmpresa(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información de clientes",
          });
        }
      })
      .catch((err) => {
        console.log("getClientesByEmpresa", err);
      });
  };

  const getMofByClientePuesto = async () => {
    setOpenModalLoader(true);
    try {
        const puesto_id = infoPuestoCliente?.id_puesto !== undefined ? infoPuestoCliente?.id_puesto : 0;
        const result = await MofService.getMofByClientePuesto(infoCliente?.id_cliente, puesto_id);
        if (result.status === CODES.SUCCESS_200) {
            if (result?.data?.length === 0) {
                Toast.fire({
                    icon: "info",
                    title: "No existen MOF regitrados",
                });
                setOpenModalLoader(false);
                return;
            }
            setMofClientePuesto(result.data);
            setOpenModalLoader(false);
        }
    } catch (error) {
        console.log(`Error en getUsuarioxMof: ${error}`);
        setOpenModalLoader(false);
    }
};

const getPuestosxCliente = async () => {
  try {
    const result = await MofService.getPuestosxCliente(infoCliente?.value);
    
    if (result.status === CODES.SUCCESS_200) {
      const data = result.data;
      
      // Verificar si result.data es un objeto y convertirlo en array
      const arrayData = Object.values(data);

      arrayData.forEach((element) => {
        element.id = element.id_puesto;
        element.value = element.id_puesto;
        element.label = element.desPuesto;
      });

      setPuestoCliente(arrayData); // Actualiza el estado con el array convertido
    } else {
      Toast.fire({
        icon: "info",
        title: "No hay información de clientes",
      });
    }
  } catch (err) {
    console.log("getPuestosxCliente", err);
  }
};
  return (
    <>
      <div
        className="row"
        style={{
          //background: "#cddcf9",
          //border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          margin: "8px",
        }}
      >
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Empresa:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar empresa"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={Number(infoEmpresa?.value) || 0}
                onChange={(value) =>
                  setInfoEmpresa(
                      companies?.find(
                          (element) => Number(element.value) === Number(value)
                      )
                  )
                }
                required={true}
                style={{ width: "100%" }}
              >
                 <Option value={0}>
                      <em>Seleccionar empresa</em>
                    </Option>
                    {companies?.map((opt) => {
                      return (
                        <Option key={"comp" + opt.id} value={opt.id}>
                          {opt.id + " - " + opt.label}
                        </Option>
                      );
                    })}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Cliente:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar cliente"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={Number(infoCliente?.value) || 0}
                onChange={(value) =>
                  setInfoCliente(
                      clienteEmpresa?.find(
                          (element) => Number(element.value) === Number(value)
                      )
                  )
                }
                required={true}
                style={{ width: "100%" }}
              >
                <Option value={0}>
                  <em>Seleccionar</em>
                </Option>
                {clienteEmpresa?.map((opt, idx) => {
                  return (
                    <Option key={`companies-${idx}`} value={opt?.value}>
                      {opt?.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Identificación del Puesto:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar puesto"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={Number(infoPuestoCliente?.value) || 0}
                onChange={(value) =>
                  setInfoPuestoCliente(
                      puestoCliente?.find(
                          (element) => Number(element.value) === Number(value)
                      )
                  )
                }
                required={true}
                style={{ width: "100%" }}
              >
                <Option value={0}>
                  <em>Seleccionar</em>
                </Option>
                {puestoCliente?.map((opt, idx) => (
                  <Option key={`puesto-${idx}`} value={opt?.value}>
                    {opt?.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <AssignMofsBody
          mofClientePuesto={mofClientePuesto} setMofClientePuesto={setMofClientePuesto}
          infoUsuarioLA={infoUsuarioLA}
          listaMof={listaMof}
          selectedRows={selectedRows} setSelectedRows={setSelectedRows}
          mofxUsuario={mofxUsuario} getDatosMofArray={getDatosMofArray}
        />
      </div>
    </>
  );
};

export default AssignMofs;
