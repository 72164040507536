import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class MofService {

    exportMof(url,{id_cliente,id_puesto,id_mof}) {
        const ruta = `${API_URL}/report/mof?url=${url}&id_cliente=${id_cliente}&id_puesto=${id_puesto}&id_mof=${id_mof}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        });
    }

    listarMof(){
        let ruta = `${API_URL}/mof/listarMof`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getDatosMof(id_mof){
        let ruta = `${API_URL}/mof/getDatosMof?id_mof=${id_mof}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    saveMof(datosMof, puestoId, clienteId,mofId,estado) {
        let ruta = `${API_URL}/mof/saveMof`;
        return axios
            .post(ruta, {
                datosMof: datosMof,
                puestoId: puestoId,
                clienteId: clienteId,
                mofId:mofId,
                estado:estado
            }, {
                headers: headerRequest()
            })
            .catch((error) => {
                return error;
            });
    }

    aprobarMof(mofId,idUsuario) {
        let ruta = `${API_URL}/mof/aprobarMof`;
        return axios
            .post(ruta, {
                mofId:mofId,
                idUsuario:idUsuario
            }, {
                headers: headerRequest()
            })
            .catch((error) => {
                return error;
            });
    }

    getAprobado(mofId,idUsuario) {
        let ruta = `${API_URL}/mof/getAprobado?mofId=${mofId}&idUsuario=${idUsuario}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }


    recepcionarMof(mofId,idUsuario) {
        let ruta = `${API_URL}/mof/recepcionarMof`;
        return axios
            .post(ruta, {
                mofId:mofId,
                idUsuario:idUsuario
            }, {
                headers: headerRequest()
            })
            .catch((error) => {
                return error;
            });
    }

    getRecepcionado(mofId) {
        let ruta = `${API_URL}/mof/getRecepcionado?mofId=${mofId}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    saveUsuarioxMof(selectedRows, usuario_id,datosMof) {
        const ruta = `${API_URL}/mof/saveUsuarioxMof`;
        const requestData = {
          selectedMof: selectedRows,
          usuario_id: usuario_id,
          datosMof: datosMof
        };
      
        return axios
          .post(ruta, requestData, {
            headers: headerRequest(),
          })
          .catch((error) => {
            return error;
          });
    }

    actualizarPermiso(mof_id,usuario_id,permiso_id) {
        const ruta = `${API_URL}/mof/actualizarPermiso`;
        const requestData = {
            mof_id: mof_id,
            usuario_id: usuario_id,
            permiso_id:permiso_id
        };
      
        return axios
          .post(ruta, requestData, {
            headers: headerRequest(),
          })
          .catch((error) => {
            return error;
          });
    }

    getUsuarioLA() {
        let ruta = `${API_URL}/mof/getUsuarioLA`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUsuarioxMof(usuario_id) {
        let ruta = `${API_URL}/mof/getUsuarioxMof?usuario_id=${usuario_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

   

    getMofByClientePuesto(cliente_id,puesto_id) {
        let ruta = `${API_URL}/mof/getMofByClientePuesto?cliente_id=${cliente_id}&puesto_id=${puesto_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getPuestosxCliente(cliente_id){
        let ruta = `${API_URL}/mof/getPuestosxCliente?cliente_id=${cliente_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
    
    getEstado(){
        let ruta = `${API_URL}/mof/getEstado`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    eliminarMof(id_mof) {
        let ruta = `${API_URL}/mof/eliminarMof?id_mof=${id_mof}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getEstandarbyCategoria(categoria_id) {
        let ruta = `${API_URL}/mof/getEstandarbyCategoria?categoria_id=${categoria_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUsuarioEditor() {
        let ruta = `${API_URL}/mof/getUsuarioEditor`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }



}



export default new MofService();
